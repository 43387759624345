@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/corner-radius';
@use 'sats-ui-lib/tokens/elevation';

.member-search {
  $breakpoint: 1000px;
  position: relative;

  &__result-wrapper {
    @include elevation.level(6);
    position: absolute;
    top: 100%;
    z-index: 10;
    border-radius: corner-radius.$s;
    max-height: 450px;
    overflow: auto;
    background: light.$background-secondary-default;
    padding: spacing.$m;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__result {
    border: none;
    background: none;
    padding: none;
    padding: spacing.$m;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: spacing.$xxs;
    text-align: left;

    &:not(:last-of-type) {
      border-bottom: 1px solid light.$ge-divider-default;
    }

    &:hover {
      background: light.$ge-divider-default;
    }
  }

  &__result-top {
    display: flex;
    gap: spacing.$xxs;
  }

  &__phone {
    color: light.$on-background-primary-alternate;
  }

  &__no-result {
    text-align: center;
  }
}
